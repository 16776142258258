import React, { useState, useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';

import useAlert from '../../Alerts/useAlert';

import '../../DataGrid.css';

// Styling 
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function ILRSiteForm(props) {
    const _apiUrl = process.env.REACT_APP_API_URL;

    const defaultRow = {
        ilrSiteId: 0,
        sapSubsystem: "",
        plantCode: "",
        siteName: "",
        description: "",
        modifiedBy: "",
        sampleFindRoutine: "",
        smapiConnection: "",
        validateUnitOfMeasure: false,
        validateLimits: false,
        validateDecimals: false
    };

    const { setAlert } = useAlert();

    useEffect(() => {
    }, []);

    const { accounts } = useMsal();

    const username = accounts[0] && accounts[0].username;

    const AddSiteflag = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? false : true;
    const SelectedValue = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? JSON.parse(props.SelectedValueTochild) : defaultRow;

    const [SiteValue, SelectedSiteValue] = React.useState(SelectedValue);
    const CloseDialoginParent = (flag) => {
        props.CloseDialog(flag);
    };

    const handleTextFieldChange = (event) => {
        SiteValue[event.target.id] = event.target.value;
        SelectedSiteValue(SiteValue);
    };


    const [uomChecked, setUomChecked] = React.useState(SelectedValue.validateUnitOfMeasure);
    const handleUOMChange = (event) => {
        setUomChecked(!uomChecked);
        SiteValue.validateUnitOfMeasure = !uomChecked;
    };

    const [limitChecked, setLimitChecked] = React.useState(SelectedValue.validateLimits);
    const handleLimitChange = (event) => {
        setLimitChecked(!limitChecked);
        SiteValue.validateLimits = !limitChecked;
    };

    const [decimalChecked, setDecimalChecked] = React.useState(SelectedValue.validateDecimals);
    const handleDecimalChange = (event) => {
        setDecimalChecked(!decimalChecked);
        SiteValue.validateDecimals = !decimalChecked;
    };

    const [sampleFindRoutine, setSampleFindRoutine] = React.useState(SelectedValue.sampleFindRoutine);
    const handleChangeSampleFindRoutine = (event) => {
        setSampleFindRoutine(event.target.value);
        SiteValue.sampleFindRoutine = event.target.value;
    };

    const saveSiteValues = async () => {
        const saveBtn = document.getElementById('btnSave');
        const serviceUrl = _apiUrl + (AddSiteflag ? "AddIlrSiteDetails" : "UpdateILRSiteDetails")

            if (SiteValue.sapSubsystem == null || SiteValue.sapSubsystem === undefined || SiteValue.sapSubsystem === "") {
                setAlert("primary", "Validation", "Please enter SAP Sub System");
                return false;
            }
            if (SiteValue.plantCode == null || SiteValue.plantCode === undefined || SiteValue.plantCode === "") {
                setAlert("primary", "Validation", "Please enter Plant Code");
                return false;
            }
            if (SiteValue.siteName == null || SiteValue.siteName === undefined || SiteValue.siteName === "") {
                setAlert("primary", "Validation", "Please enter Site Name");
                return false;
            }
            if (SiteValue.sampleFindRoutine == null || SiteValue.sampleFindRoutine === undefined || SiteValue.sampleFindRoutine === "") {
                setAlert("primary", "Validation", "Please select Sample Find Routine");
                return false;
            }

            if (SiteValue.smapiConnection == null || SiteValue.smapiConnection === undefined || SiteValue.smapiConnection === "") {
                setAlert("primary", "Validation", "Please enter SM API Connection");
                return false;
            }
            saveBtn.disabled = true;
        SiteValue.modifiedBy = username;

        await fetchDataAuthenticated(serviceUrl, "POST", JSON.stringify(SiteValue))
            .then(response => {
                if (response.ok) {
                    saveBtn.disabled = false;
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(response => {
                if (response.result)
                    CloseDialoginParent(true);
                setAlert("success", "Success", response.message);
                saveBtn.disabled = false;
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
                saveBtn.disabled = false;
            });

    }

    return (
        <DialogContent>
            <div className='container-fluid csDialogbottom'>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="sapSubsystem"
                                label="SAP Sub System"
                                disabled={!AddSiteflag ? true : false}
                                defaultValue={SiteValue.sapSubsystem}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 10 }}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="plantCode"
                                label="Plant Code"
                                defaultValue={SiteValue.plantCode}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 10 }}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="siteName"
                                label="Site Name"
                                disabled={!AddSiteflag ? true : false}
                                defaultValue={SiteValue.siteName}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 10 }}
                            />
                        </Box>
                    </div>

                </div>
                <div className='clearfix'></div>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField
                                id="description"
                                label="Description"
                                defaultValue={SiteValue.description}
                                onChange={handleTextFieldChange}
                            />
                        </Box>
                    </div>

                    <div className='col-md-4'>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="lblSampleFindRoutine" className='required'>Sample Find Routine</InputLabel>
                                <Select
                                    labelId="lblSampleFindRoutine"
                                    id="iIsActive"
                                    value={sampleFindRoutine}
                                    onChange={handleChangeSampleFindRoutine}
                                    label="IsActive *"
                                >
                                    <MenuItem value={"FindByBatchAndProduct"}>FindByBatchAndProduct</MenuItem>
                                    <MenuItem value={"FindByEvergreenBatchAndProduct"}>FindByEvergreenBatchAndProduct</MenuItem>
                                    <MenuItem value={"SampleLogin"}>SampleLogin</MenuItem>
                                    <MenuItem value={"FindByEvergreenBatchAndProduct"}>FindByEvergreenBatchAndProduct</MenuItem>
                                    <MenuItem value={"FindUsingMultipleSamples"}>FindUsingMultipleSamples</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="smapiConnection"
                                label="SM API Connection"
                                defaultValue={SiteValue.smapiConnection}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 10 }}
                            />
                        </Box>
                    </div>
                </div>
                <div className='clearfix'></div>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <label>
                                <input
                                    type="checkbox"
                                    defaultChecked={uomChecked}
                                    onChange={handleUOMChange}
                                /> Validate Unit Of Measure
                            </label>
                        </Box>
                    </div>

                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <label>
                                <input
                                    type="checkbox"
                                    defaultChecked={limitChecked}
                                    onChange={handleLimitChange}
                                /> Validate Limits
                            </label>
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <label>
                                <input
                                    type="checkbox"
                                    defaultChecked={decimalChecked}
                                    onChange={handleDecimalChange}
                                /> Validate Decimals
                            </label>
                        </Box>
                    </div>

                </div>

            </div>
            <div className='clearfix'></div>
            <div className='row'>
                <div className='col-md-9'></div>
                <div className='col-md-3 row'>
                    <div className='col-md-6'>
                        <button type="button" className="btn btn-outline-secondary" onClick={CloseDialoginParent}>Cancel</button>
                    </div>
                    <div className='col-md-6'>
                        <button type="button" id="btnSave" className="btn btn-outline-primary" onClick={saveSiteValues}>Save</button>
                    </div>
                </div>
            </div>
        </DialogContent>

    );
}
export default ILRSiteForm;