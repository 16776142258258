import React, { useState, useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import { fetchDataAuthenticated } from '../../../utilities/HttpUtils';

import useAlert from '../../Alerts/useAlert';

import '../../DataGrid.css';

// Styling 
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

function SampleByBlendForm(props) {
    const _apiUrl = process.env.REACT_APP_API_URL;

    const defaultRow = {
        gomRequestId: 0,
        blendDate: "",
        Tank: "",
        Blend: "",
        Plant: "",
        Customer: "",
        Formula: "",
        Language: "",
        CmcsCode: "",
        NominationKey: "",
        NominationItem: "",
        SequenceId: "",
        GomType: 1,
        ResponseToGom: "",
        RequestedBy: ""
    };

    const { setAlert } = useAlert();
    const SelectedValue = (props && props.SelectedValueTochild && props.SelectedValueTochild != "") ? JSON.parse(props.SelectedValueTochild) : defaultRow;

    const [BlendValue, SelectedBlendValue] = React.useState(SelectedValue);

    const { accounts } = useMsal();

    const username = accounts[0] && accounts[0].username;
    const CloseDialoginParent = (flag) => {
        props.CloseDialog(flag);
    };
    const handleTextFieldChange = (event) => {
        BlendValue[event.target.id] = event.target.value;
        SelectedBlendValue(BlendValue);
    };


    const saveSampleByBlendValues = async () => {

        const serviceUrl = _apiUrl + "GomRequestSampleByBlend"
        if (BlendValue.plant == null || BlendValue.plant === undefined || BlendValue.plant === "") {
            setAlert("primary", "Validation", "Please enter Plant");
            return false;
        }
        if (BlendValue.formula == null || BlendValue.formula === undefined || BlendValue.formula === "") {
            setAlert("primary", "Validation", "Please enter Formula");
            return false;
        }
        if (BlendValue.tank == null || BlendValue.tank === undefined || BlendValue.tank === "") {
            setAlert("primary", "Validation", "Please enter Tank");
            return false;
        }

        BlendValue.requestedBy = username;

        await fetchDataAuthenticated(serviceUrl, "POST", JSON.stringify(BlendValue))
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                //throw new Error;
            })
            .then(response => {
                if (response.result)
                    CloseDialoginParent(true);
                setAlert("success", "Success", response.message);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                console.error(error);
            });

    };

    return (

        <DialogContent>
            <div className='container-fluid csDialogbottom'>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="plant"
                                label="Plant"
                                defaultValue={BlendValue.plant}
                                onChange={handleTextFieldChange}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="formula"
                                label="Formula"
                                defaultValue={BlendValue.formula}
                                onChange={handleTextFieldChange}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField required
                                id="tank"
                                label="Tank"
                                defaultValue={BlendValue.tank}
                                onChange={handleTextFieldChange}
                            />
                        </Box>
                    </div>


                </div>
                <div className='clearfix'></div>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField
                                id="blend"
                                label="Blend"
                                defaultValue={BlendValue.blend}
                                onChange={handleTextFieldChange}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField
                                id="blendDate"
                                label="BlendDate"
                                defaultValue={BlendValue.blendDate}
                                onChange={handleTextFieldChange}
                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField
                                id="customer"
                                label="Customer"
                                defaultValue={BlendValue.customer}
                                onChange={handleTextFieldChange}

                            />
                        </Box>
                    </div>

                </div>
                <div className='clearfix'></div>
                <div className='row mt-2'>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField
                                id="language"
                                label="Language"
                                defaultValue={BlendValue.language}
                                onChange={handleTextFieldChange}
                                inputProps={{ maxLength: 2 }}

                            />
                        </Box>
                    </div>
                    <div className='col-md-4'>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 0 },
                            }}
                        >
                            <TextField
                                id="cmcsCode"
                                label="CmcsCode"
                                defaultValue={BlendValue.cmcsCode}
                                onChange={handleTextFieldChange}
                               
                            />
                        </Box>
                    </div>

                </div>
                <div className='clearfix'></div>
            </div>
            <div className='clearfix'></div>
            <div className='row'>
                <div className='col-md-7'></div>
                <div className='col-md-5 row'>
                    <div className='col-md-6'>
                        <button type="button" className="btn btn-outline-secondary" onClick={CloseDialoginParent}>Cancel</button>
                    </div>
                    <div className='col-md-6'>
                        <button type="button" className="btn btn-outline-primary" onClick={saveSampleByBlendValues}>Send Request</button>
                    </div>
                </div>
            </div>

            <div className='clearfix'></div>
            <div className='clearfix'></div>
        </DialogContent >

    );
}
export default SampleByBlendForm;