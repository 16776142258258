import React, { useState } from "react";
import { Link } from 'react-router-dom';

const GOMMappingSubMenu = () => {
    const [dropdown, setDropdown] = useState(false);
    return (
        <ul className={dropdown ? "subMenu clicked" : "subMenu"} onClick={() => setDropdown(!dropdown)}>
            <li><Link to='/SiteDetails' className='submenu-item'>GOM Site</Link></li>
            <li><Link to='/ComponentGroupLookup' className='submenu-item'>Component Group Lookup</Link></li>
            <li><Link to='/ComponentMapping' className='submenu-item'>Component Mapping</Link></li>
            <li><Link to='/Translation' className='submenu-item'>Translation</Link></li>
            <li><Link to='/GomRequestGrid' className='submenu-item'>GOM Request</Link></li>
        </ul>
    );
};

export default GOMMappingSubMenu;